export default {
  sections: {
    contactInformation: 'Contact information',
    personalData: 'Personal data',
    emailAndPhone: 'Email and phone',
    address: 'Address',
    generalData: 'General data',
    information: 'Information',
    fiscalData: 'Fiscal data',
    fiscalAddress: 'Fiscal address',
    contactPerson: 'Contact person',
    rates: 'Rates',
    defaultsRates: 'Defaults Rates',
    networkRate: 'Networks rate',
    shopRate: 'Shops rate',
    salesRepresentative: 'sales representative',
    capabilities: 'capabilities',
    status: 'status',
    openingSchedule: 'opening schedule',
    schedule: 'schedule',
    commissions: 'commissions',
    commissionsData: 'commissions data',
    salesSettings: 'sales Settings',
    delivery: 'Delivery',
    shopReturn: 'Shop Return',
    logisticsReverse: 'Logística Reverse',
    logisticsDirect: 'Logística Direct'
  },
  infoSections: {
    zones: 'Can select more than one zone.',
    rateHierarchy: {
      global:
        'Global rates for networks and stores that do not have specific rates. <br/> <br/> <b> These rates apply to forward and reverse logistics. </b>',
      network:
        'Enter specific rates so that different prices are paid on this network to the network and its stores. <br/> <br/> If not select default rates to apply global settings.',
      shop: 'Enter specific rates so that in this store different prices are paid to the network and the store. <br/> <br/> If not select default rates to apply the network configuration or if there is no global one.',
    },
    networkRate: {
      info: 'The price paid to the <b> network </b> per expedition is the sum of the prices of each package, where each package is calculated as: <br/> <br/> <b> Price per package = Fixed Price + (Package weight - Threshold) * Price per additional kg </b>',
    },
    shopRate: {
      info: 'The price paid to the <b> store </b> per expedition is the sum of the prices of each package, where each package is calculated as: <br/> <br/> <b> Price per package = Fixed Price + (Package weight - Threshold) * Price per additional kg </b>',
      newRates: 'The price paid to the <b> store </b> per expedition is the sum of the prices of each package, where each package is calculated as: <br/> <br/> <b> Price per package = Fixed Price + (Package weight - Threshold) * Price per additional kg </b>',
    },
    schedule:
      'On each day set one schedule if they do not close for lunch, set both if they close for lunch, or set none when it is closed for the day',
  },
  labels: {
    name: 'name',
    username: 'username',
    dni: 'identification number',
    nif: 'TAX id',
    idNum: 'ID number',
    password: 'password',
    tooglePassword: 'tooglePassword',
    loginEnabled: 'login enabled',
    squareCode: 'Place code',
    clientCode: 'Client code',
    parcelShopId: 'Parcel Shop Id',
    reportOperations: {
      label: 'Networks',
      status: 'Status',
      PS_GLS_PT: 'PS GLS PT',
      PS_GLS_PT_Agencias: 'PS GLS PT Agencias',
    },
    email: {
      label: 'label',
      address: 'email',
    },
    dialogs: {
      title: 'ATTENTION',
      capabilitiesNotSelected:
        'When switching from a premium network the capacities have been deleted, please fill in the corresponding capacities for the new network.',
      buttonOK: 'OK',
    },
    phone: {
      label: 'label',
      number: 'phone',
    },
    address: {
      address: 'address',
      postalCode: 'postal code',
      locality: 'locality',
      country: 'country',
      latitude: 'latitude',
      longitude: 'longitude',
      door: 'door',
    },
    searchGoogle: 'Search address on Google',
    zones: 'zones',
    enabled: 'enabled',
    businessName: 'business name',
    activity: 'activity',
    channelingAgencyCode: 'Direct Canalization Agency',
    retention: 'retention',
    iban: 'IBAN number',
    paymentMethod: 'payment method',
    dueDays: 'due days',
    useDefaultValues: 'use default rates',
    networkRate: {
      constant: 'fixed price',
      constantShopReturn: 'Fixed price Shop Return',
      variableStart: 'weight threshold in kg',
      variable: 'price per additional kg',
    },
    shopRate: {
      constant: 'fixed price',
      constantShopReturn: 'Fixed price Shop Return',
      variableStart: 'weight threshold in kg',
      variable: 'price per additional kg',
    },
    countries: {
      spain: 'Spain',
      portugal: 'Portugal',
    },
    titleExcelDialog: 'Attention',
    contextExcelDialog: 'The following result does not return data, try changing the report parameters.',
    accept: 'Accept',
    network: 'network',
    zone: 'zone',
    id: 'uuid',
    timezones: 'time zones',
    isEco: 'Eco shop',
    isGold: 'PS Gold',
    QRDinamic: 'Dinamic QR',
    isRadical: 'PS Radical',
    openingRepresentative: 'opening representative',
    countryShop: 'Country of shops',
    since: 'since',
    to: 'to',
    managingRepresentative: 'managing representative',
    deliver: 'deliver',
    pickup: 'pickup',
    reason: 'reason',
    definitiveClosure: 'definitive closure',
    temporaryClosure: 'temporary closure',
    switchSchedule: 'same schedule from monday to friday',
    firstSchedule: 'schedule',
    secondSchedule: 'second schedule',
    monday: 'monday',
    tuesday: 'tuesday',
    wednesday: 'wednesday',
    thursday: 'thursday',
    friday: 'friday',
    saturday: 'saturday',
    weekly: 'monday to friday',
    all: 'all',
    incoming: 'incoming',
    inShop: 'in shop',
    inShopExpired: 'expired',
    delivered: 'delivered',
    searchByName: 'search by name',
    commissions: {
      eachShopOpening: 'each shop opening',
      minimumOpeningsPerMonth: 'min. openings per month',
      eachShopManagement: 'each shop management',
      minimumManagementMonths: 'min. management months',
    },
    loading: 'loading',
    signin: 'log in',
    byName: 'name',
    byFiscal: 'fiscal data',
    placeHolderByName: 'search by name',
    placeHolderByFiscal: 'search by business or nif',
    type: 'type',
    shop: 'shop',
    codeExp: 'expedition code',
    agency: {
      code: 'agency code',
      name: 'agency name',
    },
    status: 'status',
    deliveryStatus: 'direct logistic status',
    pickupStatus: 'inverse logistic status',
    tracking: {
      derivedAt: 'derived',
      receivedFromDriverAt: 'received from driver',
      receivedFromCustomerAt: 'received from customer',
      expiredAt: 'expired',
      deliveredToCustomerAt: 'delivered to customer',
      returnedToDriverAt: 'returned to driver',
      deliveredToDriverAt: 'delivered to driver',
    },
    deliveredOrReturned: 'Delivered / returned',
    sell: 'sell',
    sellplus: 'sell +',
    networkPercentage: 'Network percentage',
    shopPercentage: 'Shop percentage',
  },
  contactForm: {
    nameSurname: 'Name and Surname',
    number: 'Phone',
    email: 'Email',
  },
  errors: {
    required: 'Required field',
    CPWrong: 'Enter a valid zip code',
    enterSquareCode: 'Enter a place code',
    limitSquareCode: 'Square Code cannot exceed 32767',
    limitClientCode: 'Client Code cannot exceed 32767',
    enterClientCode: 'Enter a client code',
    min: 'Minimum {{number}} characters',
    country: 'ISO 3166-1 alpha 2 country code',
    allRequiredOrNone: 'All fields required or none',
    password: 'At least one number, one uppercase, one lowercase',
    uuid: 'It should be a valid UUID',
    enterName: 'Enter a name',
    enterNif: 'Enter TAX ID',
    enterUsername: 'Enter a username',
    enterEmail: 'Should be a valid email',
    emailAndLabel: 'Address email and label required or empty',
    positiveNumber: 'Should be a positive number',
    paymentMethod: 'Should be a transfer or confirming',
    iban: 'Invalid IBAN number',
    rangeHourOrEmpty: 'It has to be a valid time range or an empty field',
    closeHourAfterOpenHour: 'The closing time must be after the opening time',
    afternoonHourAfterMorningHour: 'The afternoon schedule must be after the morning schedule',
    latitude: 'Latitude must be between -90 and 90',
    longitude: 'Longitude must be between -180 and 180',
    latitudeNot0: 'Latitude cannot be 0',
    longitudeNot0: 'Longitude cannot be 0',
    number: 'Should be a number',
    username: 'Should be uppercase, lowercase or numbers',
    nifError: 'DNI, NIE or Passport ID incorrect',
  },
};
